<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
      <div class="card card-primary border border-secondary " >
          <div class="card-header p-3 mb-2 bg-primary text-white">
              <i class="bi bi-arrow-up-circle me-2"></i><span class="mb-4">{{ view.title }}</span>
          </div>
          <div class="card-body">
          <form [formGroup]="formUpDiscount">
              <div class="row">
                  <div class="input-group col-sm-12 col-md-12 col-lg-9 col-xl-6">
                    <div class="form-floating flex-grow-1">
                      <input 
                      type="text"
                      name="orderId"
                      placeholder="No. Orden"
                      class="form-control"
                      required
                      [(ngModel)]="itemsModel.orderId"
                      formControlName="orderId"
                      />
                          <label for="orderId">No. Orden</label>
                      </div>
                    <div class="input-group-append">
                      <button type="button" class="btn btn-dark mr-2" (click)="getOrderData()">
                          Solicitar Información
                      </button>
                    </div>
                  </div>
              </div>
              <div class="text-sm-end">
                  <button *ngIf="showButtonSearch" type="button" class="btn btn-info mr-2" (click)="sendRushOrder()">
                      Envíar Rush
                  </button>
                </div>
          </form>
          </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 px-4" *ngIf="itemsModel.orderTotal > 0">
  </div>
  <div class="container-fluid pt-4 px-4">
    <div class="row g-4">
      <div class="row g-5">
          <ng-template [ngIf]="view.isLoading" >
              <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status" >
                      <span class="sr-only" id="loading"></span>
                  </div>
              </div>
          </ng-template>
      </div>
  
      <div class="col-sm-12">
          <div class="bg-light rounded h-100 p-4 table-responsive" *ngIf="!view.isLoadingInyection">
              <div class="col-sm-4">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar"
                    (keyup)="updateFilter($event)"
                  />
                  <label for="Buscar">Buscar</label>
                </div>
              </div>
              <div class="row g-5">
                  <div class="col-md-12 col-lg-12">
                      <ngx-datatable 
                        [rows]="itemsData"
                        [footerHeight]="50"
                        rowHeight="auto"
                        [headerHeight]="50"
                        [limit]="200"
                        class="material"
                        [columnMode]="'force'"
                        [summaryRow]="true"
                        [summaryPosition]="'auto'"
                        [limit]="200"
                        [reorderable]="reorderable"
                        [scrollbarH]="true"
                      >
                          <ngx-datatable-column name="Producto">
                              <ng-template let-row="row" ngx-datatable-cell-template>
                                  <div class="col-md-11">
                                      <div class="row">
                                          <div class="col-md-5">
                                              <a href="#">
                                                  <img [src]="row.photo_url">
                                              </a>
                                          </div>
                                          <div class="col-md-7">
                                             {{row.name}}
                                             <h6 class="my-0">{{row.attributes.sku}}</h6>
                                          </div>
                                      </div>
                                  </div>
                              </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column name="QTY">
                              <ng-template let-row="row"  name="QTY" ngx-datatable-cell-template>
                                  <div>{{row.quantity}}</div>
                              </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column prop="partner_price" name="Precio">
                              <ng-template
                                let-row="row"
                                let-value="value"
                                ngx-datatable-cell-template
                              >
                                  <div>
                                      ${{value}}
                                  </div>
                              </ng-template>
                          </ngx-datatable-column>
                          <ngx-datatable-column prop="attributes.sku" name="Eliminar">
                           <ng-template
                            let-row="row"
                            let-value="value"
                            ngx-datatable-cell-template
                          >
                              <div>
                                  <button type="button" class="btn btn-danger" (click)="editSku(value)"><i class="far fa-trash-alt"></i></button>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      </ngx-datatable>
                  </div>      
              </div>
          </div>
              </div>
    </div>
  </div>
