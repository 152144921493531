<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
      <div class="row">
          <h6 class="mb-4">{{view.title}}</h6>
      </div>

      <div class="row">
          <div class="col-sm-3">
              <div class="form-floating mb-3">
                  <select class="form-control" id="type" name="type" [(ngModel)]="type">
                      <option value=""></option>
                      <option value="SKU">Por SKU</option>
                      <option value="IDLOGIX">Por ID</option>
                  </select>
                  <label for="type">Tipo</label>
              </div>
          </div>

          <div class="col-sm-3">
              <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="inputSend" [(ngModel)]="inputSend"
                      [disabled]="type == '' ? true : false">
                  <label for="inputSend">{{type}}</label>
              </div>
          </div>

          <div class="col-sm-3">
              <div class="form-floating mb-3">
                  <select class="form-select" id="tienda" name="tienda" [(ngModel)]="Filtros.tienda" [disabled]="type == 'Id' || type == '' || type == 'Evento' ? true : false">
                      <option value="" selected>Todas</option>
                      <option *ngFor="let store of stores" value="{{store.storeId}}">{{store.name}}</option>
                  </select>
                  <label for="tipo">TIENDA</label>
              </div>
          </div>

      </div>
      <button class="btn btn-primary" (click)="listar()" [disabled]="view.isLoading" style="margin-right: 10px;">Buscar</button>
      <button class="btn btn-warning" (click)="clear()" [disabled]="view.isLoading">Limpiar</button>
  </div>
</div>


<div  *ngIf="showFind" class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
      <div class="bg-light rounded h-100 p-4 table-responsive">
       
        <div *ngIf="showFind" class="col-sm-4">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              (keyup)="updateFilter($event)"
            />
            <label for="Buscar">Buscar</label>
          </div>
        </div>

       


        <ngx-datatable 
          [rows]="filteredRows"
          [columns]="columns"
          [footerHeight]="50"
          rowHeight="auto"
          [headerHeight]="50"
          [limit]="8"
          class="bootstrap"
         
          [columnMode]="'standard'"
          [summaryRow]="true"
          [summaryPosition]="'auto'"
          [reorderable]="reorderable"
          [scrollbarH]="true"
        >
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>