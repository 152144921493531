import { Component ,OnInit} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

interface view {
  title: string,
  isLoading: boolean,
  isLoadingInyection: boolean
}

interface itemsModel {
  orderId: null,
  orderTotal: any
}


@Component({
  selector: 'app-push-rorders',
  templateUrl: './push-rorders.component.html',
  styleUrls: ['./push-rorders.component.css']
})
export class PushRordersComponent implements OnInit{

  itemsData : any[] = [];
  globalData : any[] = [];
  itemDataRes : any[] = [];
  itemsModel : itemsModel = {
    orderId: null,
    orderTotal: 0
  }
  reorderable = true;
  isDisabled = false;
  applyFilter = false;
  applyFilterData = '';
  view: view = {
    title: 'Rush Order',
    isLoading: false,
    isLoadingInyection: true
  }
  showButtonSearch : boolean = false;

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private formBuilder: FormBuilder,
    private appService:AppService,
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    //this.getStoreFilterPromo();
  }

  formUpDiscount = this.formBuilder.group({orderId:['']});
  async getOrderData() {
    this.showButtonSearch = false;
    this.view.isLoading = true;
    if (this.formUpDiscount.value.orderId) {
      var data = {orderIdVtex: this.formUpDiscount.value.orderId};
      let dataOrder = JSON.parse(JSON.stringify(data));
      this.API.getRushOrder(dataOrder).subscribe((data: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;
        this.view.isLoadingInyection = false;
        //console.log(data)
        if (typeof data[0] != "undefined") {
          this.itemsData = Object.values(data[0].json.body.collections[0].order_sections[0].items);
          this.itemDataRes = Object.values(data[0].json.body.collections[0].order_sections[0].items);
          this.globalData = Object.values(data);
          //console.log(this.globalData);
          this.itemsModel.orderTotal = data[0].json.body.collections[0].header.customer.order_total;
        } else {
          this.itemsData = [];
          this.itemDataRes = [];
          this.globalData = [];
          //console.log(this.globalData);
          this.itemsModel.orderTotal = 0;
          this.appService.getShowModalInfoFormat("ATENCIÓN","No se encontraron datos con esa orden.","info");
        }
      }, (e: any) => {
        this.showButtonSearch = true;
        this.view.isLoading = false;
        this.appService.getShowModalInfoFormat("ERROR","Ocurrio un error al momento de obtener la información de la orden, intente nuevamente.","error");
        console.log('getOrderDataRush -> ', e);
      });
    } else {
      this.showButtonSearch = true;
      this.view.isLoading = false;
      this.appService.getShowModalInfoFormat("ATENCIÓN","Debe colocar una número de orden.","warning");
    }
  }
  async sendRushOrder() {
    this.view.isLoading = true;
    this.showButtonSearch = false;
    this.view.isLoadingInyection = true;
    this.globalData[0].json.body.collections[0].header.oms.workflow = 'VTEX Cat';
    this.API.rushOrderProcess(this.globalData).subscribe((data: any) => {
      if (data.success == true) {
        this.formUpDiscount.reset();
        this.itemsData = [];
        this.itemDataRes = [];
        this.globalData = [];
        this.itemsModel.orderTotal = 0;
        this.view.isLoading = false;
        this.appService.getShowToastFormat(data.result,"success");
      } else {
        this.appService.getShowModalInfoFormat("ERROR","Ocurrio un error al realizar la rush, intente nuevamente.","error");
        console.log(data);
        this.view.isLoadingInyection = false;
        this.view.isLoading = false;
      }
    }, (e: any) => {
      this.appService.getShowModalInfoFormat("ERROR","Ocurrio un error al realizar el proceso de rush order, intente nuevamente.","error");
      console.log('processInyection -> ', e);
      this.view.isLoadingInyection = false;
    });
  }

  editSku(sku: any): void {
    if (this.applyFilter) {
      this.itemsData = this.itemDataRes;
    } 
    this.itemsData = this.remove(this.itemsData, sku)
  }

  remove(array: any, sku: any){
    let totalDiscount = 0;
    let newData = array.filter(function(element: any){
      if (element.attributes.sku != sku) {
        totalDiscount = totalDiscount + element.partner_price * element.quantity;
      }
      return element.attributes.sku != sku;
    });
    totalDiscount = Math.abs(totalDiscount);
    totalDiscount = totalDiscount;
    let totalUpdate = totalDiscount.toFixed(2);
    this.itemsModel.orderTotal = totalUpdate;
    this.globalData = this.globalData.filter(function(element: any){
      element['json'].body.collections[0].header.customer.order_total = totalUpdate;
      element['json'].body.collections[0].order_sections[0].ticket.ticket_amount = totalUpdate;
      if (typeof element['json'].body.collections[0].order_sections[0].ticket.payments[0] != "undefined" && 
          typeof element['json'].body.collections[0].order_sections[0].ticket.payments[0].amount != "undefined") {
        element['json'].body.collections[0].order_sections[0].ticket.payments[0].amount = totalUpdate;
      }
      element['order_total'] = parseInt(totalUpdate.toString().replace('.', ''));
      element['base_grand_total'] = parseInt(totalUpdate.toString().replace('.', ''));
      element['json'].body.collections[0].order_sections[0].items = element['json'].body.collections[0].order_sections[0].items.filter(function(elements: any){
       return elements.attributes.sku != sku;
      });
      return element;
    });
    this.itemDataRes = newData;
    return newData;
  }

  updateFilter(event : any): void {
    let val = event.target.value.toLowerCase();
    this.applyFilter = false;
    this.applyFilterData = '';
    if (event.target.value) {
      let temp = this.itemsData.filter(function(d: any) {
        return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.applyFilter = true;
      this.itemsData = temp;
      this.applyFilterData = val;
    } else {
      this.itemsData = this.itemDataRes;
    }
  }
}