import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarService } from 'src/app/services/side-bar.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FormControl,FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import * as XLSX from 'xlsx';
import { EMPTY } from 'rxjs';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import  "./conciliacion.component.css";

//import { saveAs } from 'file-saver';



type AOA = any[][];
interface view {
  title: string,
  isLoading: boolean,
  _url: string
}

interface DiscountData {
  startDate: string | null,
  endDate: string | null,
  startHour: string | null,
  endHour: string | null,
  store       : any | null,
  payment_code: any | null
}



@Component({
  selector: 'app-conciliacion',
  templateUrl: './conciliacion.component.html',
  styleUrls: ['./conciliacion.component.css']
})
export class ConciliacionComponent implements OnInit {


  loadingIndicator              = true;
  reorderable                   = true;
  options                       = {}
  dat           : any           = [];
  columns       : any           = {};
  columns2       : any           = {};
  filteredRows  : any           = []; // inicialmente son los mismos que rows
  payments  : any           = []; // inicialmente son los mismos que rows
  skus          : any           = []; 
  resultados    : any[]         = [];
  DiscountData  : DiscountData  = this.emptyFilters();
  arrayData: any = [];
  arrayHeader: any = [];
  storeData : any = [];
  paymentData : any = [];
  opcionSeleccionada: string = ''; // Variable para almacenar la opción seleccionada

  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;

  view: view = {
    title: 'CONCILIACIÓN',
    isLoading: false,
    _url: `/ods/csn`
  }

  constructor(
    private API: ApiService,
    private Helper: HelperService,
    private sidebar: SideBarService,
    private appService:AppService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.sidebar.showLoading();
    this.showFind = false;
    //this.getStoreFilterPromo();
    this.opcionSeleccionada = "";
    this.storeData = this.appService.getStoreData();
    this.paymentData = this.appService.getPaymentData();
    this.appService.getPaymentData(),
    this.columns = [
      { name: 'ORDER REFERENCE', prop: 'order_reference' },
      { name: 'CUSTOMER REFERENCE', prop: 'customer_reference' },
      { name: 'CREATE DATE', prop:'create_date'},
      { name: 'STORE ID', prop: 'store_id' },
      { name: 'STATUS', prop: 'status' },
      { name: 'PAYMENTS', prop:  'payments' }
    ];
    this.columns2 = [
      { name: 'id', prop: 'tid' },
      { name: 'name', prop: 'name' }
    ];
  }


  emptyFilters(): DiscountData {
    return {
      startDate     : null,
      endDate       : null,
      startHour     : "",
      endHour       : "",
      store         : "",
      payment_code  : "",
    };
  }

  formUpDiscount = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    startHour : [''],
    endHour : [''],
    store:[''],
    payment_code:['']
  });

  validForm(event: Event) {
    event.preventDefault();
    if(this.formUpDiscount.valid) {

      const startDate = new Date(this.formUpDiscount.value.startDate);
      const endDate = new Date(this.formUpDiscount.value.endDate);

      if (startDate > endDate) {
        this.appService.getShowModalInfoFormat("¡AVISO!",'La fecha de inicio no puede ser posterior a la fecha de fin. ', "info");
      } else {
      
      //const fechaFormateada  =   this.formatDates( startDate);
      this.DiscountData.startDate =  this.formUpDiscount.value.startDate;
      this.DiscountData.endDate =  this.formUpDiscount.value.endDate;
      //this.DiscountData.store =  this.opcionSeleccionada;
      //this.DiscountData.payment_code =  this.formUpDiscount.value.payment_code;
     
        this.agregarDiscount();
      }
    } else {
      this.formUpDiscount.markAllAsTouched();
    }
  }

  formatDates( date: any) : string {
    let fechaFormateada  =   formatDate( date, 'yyyy-MM-dd HH:mm:ss','en_US','+0430');
    return fechaFormateada;
  }
  
  agregarDiscount() : void {

    this.resultados = [];
    this.dat        = [];
    this.view.isLoading = true;
    this.showButton     = false;
    this.showFind = false;
    this.API._get(this.view._url, this.DiscountData).subscribe((res: any) => {
  
      this.view.isLoading = false;
      this.showButton     = true;
     
      //console.log( "ress ---> " + res );
      if(res.result == 'ok') {
        if (  res.data != ""  ) {
          this.appService.getShowToastFormat("Información exitosa.","success");
          this.dat =  res.data ;
          this.filteredRows = this.dat;
          this.showFind = true;
        }else{
          this.showFind = false;
          //console.log("dat  " + JSON.stringify( res.data));
        this.appService.getShowModalInfoFormat("¡AVISO!","No hay información relacionada con los datos proporcionados.","info");
        //this.Helper.show('No existe informacion en base a la proporcionado '  , { classname: `bg-warning text-light`, delay: 1500 });
        }

      } else {
        if(res.e.name === 'TokenExpiredError') {
          this.Helper.logOut();
        } else {
          this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la respuesta del servicio:  "+ res.e,"error");
          //this.Helper.show('Ocurrio un error al obtener los datos RESPONSE ' + res.e , { classname: `bg-danger text-light`, delay: 1500 });
        }
      }
    }, (e: any) => {
      this.appService.getShowModalInfoFormat("ERROR RESPONSE","Error en la llamada del servicio:  "+ e,"error");
      console.warn('search Discount -> ', e);
    });

  }

  updateFilter(event : any) {
    const val = event.target.value.toLowerCase();
  
    // filtrar los datos según el término de búsqueda
    const temp = this.dat.filter(function(d: any) {
      return JSON.stringify(d).toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // actualizar la variable que contiene los datos filtrados
    this.filteredRows = temp;
  }

    // EXCEL READ 
    dataR: AOA = [[1, 2], [3, 4]];
  
    wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    fileName: string = 'CONCILIACION.xlsx';

    export(): void {
      this.view.isLoading = true;
      /* generate worksheet */



    /*  this.arrayHeader[0]  =  [
                              'ORDER REFERENCE',                     
                              'CUSTOMER REFERENCE',                                        
                              'CREATE DATE',                           
                              'STORE ID',                          
                              'STATUS',                   
                              'PAYMENTS'
                        ];                 *7/
      
     /* this.arrayData = this.dat.map((objeto: { 
                                                order_reference : any;
                                                customer_reference : any;               
                                                create_date : any;
                                                store_id : any;
                                                status : any;
                                                payments : any;
                                            }) => [
                                                    objeto.order_reference, 
                                                    objeto.customer_reference, 
                                                    this.formatDates(objeto.create_date),
                                                    objeto.store_id,
                                                    objeto.status,
                                                    JSON.stringify (objeto.payments)
                                                  ]);*/
      //this.arrayHeader.push(this.arrayData);

     // const newArray =  this.arrayHeader.concat(this.arrayData);


      //console.log((newArray));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.flattenData(this.filteredRows ));
      //const worksheet = XLSX.utils.json_to_sheet(this.flattenData(this.jsonData));
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
      this.view.isLoading = false;
    }
  
  /*
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.flattenData(this.arrayData));
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      //saveAs(blob, 'data.xlsx');
    }*/

    flattenData(data: any[]): any[] {
      const flattenedData: any[] = [];
  

      data.forEach(item => {
        item.payments.forEach((payment: {
          authorization_date: any; tid: any; name: any; amount: any; id:any; payment_code:any;
}) => {
          flattenedData.push({
            order_reference: item.order_reference,
            customer_reference: item.customer_reference,
            store_id: item.store_id,
            tid: payment.tid,
            name: payment.name,
            amount: payment.amount,
            payment_code: payment.payment_code,
            authorization_date : payment.authorization_date,
            id:payment.id
          });
        });
      });
  
      return flattenedData;
    }

  public showButton : boolean = true;
  public showFind : boolean = false;

    /**
     * 
     * DOCUMENTACTION LINK https://sweetalert2.github.io/#themes
     */

  showAlert() {
      this.appService.getShowModalInfoFormat("¡ERROR!","No se recicbio respuesta del serivicio","error");
  }

  

  showConfirm() {

    this.appService.showConfirm();
  }

  


}
