<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
    <div class="card card-primary border border-secondary " >

     
          <div class="card-header p-3 mb-2 bg-primary text-white">
            <i class="fa fa-briefcase me-2"></i><span class="mb-4">{{ view.title }}</span>
          </div>

    <div class="card-body">
 
    <form [formGroup]="formUpDiscount" (ngSubmit)="validForm($event)">

    <div class="row">

      <div class="col-sm-2">
        <div class="form-floating mb-3">
          <input 
            type="text"
            name="sku"
            placeholder="Sku"
            class="form-control"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            [(ngModel)]="Filtros.sku"
            formControlName="sku"
          />
          <label  for="sku">Sku</label>
        </div>
      </div>

      <div class="col-sm-2">
          <div class="form-floating mb-3">
            <select   
            id="store" 
            name="store"
            placeholder="Tienda"
            class="form-control"
            
           
            formControlName="store"  
            [(ngModel)]="Filtros.store">
              <option *ngFor="let opcion of storeData" [value]="opcion.storeId">{{ opcion.name}}</option>
              
            </select>

            <label for="store">Tienda</label>
          </div>
        </div>


    </div>
    
    
    <div class="form-floating">
      <div class="text-sm-end">
        <button *ngIf="showButton" type="submit" class="btn btn-dark">
          Solicitar Información
        </button>
        <ng-template [ngIf]="view.isLoading" >
          <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status" >
                <span class="sr-only" id="loading"></span>
              </div>
            </div>
      </ng-template>
      </div>
    </div>
   
    </form>
 
 
  </div>

</div>
</div>
</div>

<div  *ngIf="showFind" class="container-fluid pt-4 px-4">
  <div class="row g-4">
    <div class="col-sm-12">
      <div class="bg-light rounded h-100 p-4 table-responsive">
       
        <div *ngIf="showFind" class="col-sm-4">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Buscar"
              (keyup)="updateFilter($event)"
            />
            <label for="Buscar">Buscar</label>
          </div>
        </div>

       


        <ngx-datatable 
          [rows]="filteredRows"
          [columns]="columns"
          [footerHeight]="50"
          rowHeight="auto"
          [headerHeight]="50"
          [limit]="8"
          class="bootstrap"
          [columnMode]="'force'"
          [summaryRow]="true"
          [summaryPosition]="'auto'"
          [reorderable]="reorderable"
          [scrollbarH]="true"
        >
          
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
