<div class="container-fluid pt-4 px-4">
    <div class="bg-light rounded-top p-4">
      <div class="card card-primary border border-secondary " >
          <div class="card-header p-3 mb-2 bg-primary text-white">
              <i class="bi bi-arrow-up-circle me-2"></i><span class="mb-4">{{ view.title }}</span>
          </div>
          <div class="card-body">
          <form [formGroup]="formUpDiscount">
              <div class="row">
                  <div class="input-group col-sm-12 col-md-12 col-lg-9 col-xl-6">
                    <div class="form-floating flex-grow-1">
                      <input 
                      type="text"
                      name="orderId"
                      placeholder="No. Orden"
                      class="form-control"
                      required
                      [(ngModel)]="itemsModel.orderId"
                      formControlName="orderId"
                      />
                          <label for="orderId">No. Orden</label>
                      </div>
                    <div class="input-group-append">
                      <button *ngIf="showButtonSearch" type="button" class="btn btn-dark mr-2" (click)="getOrderData()">
                          Solicitar Información
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-12 justify-content-center pt-4">
                    
                      <ng-template [ngIf]="view.isLoading" >
                          <div class="d-flex justify-content-center">
                              <div class="spinner-border" role="status" >
                                  <span class="sr-only" id="loading"></span>
                              </div>
                          </div>
                      </ng-template>
                  </div>
              </div>
          </form>
          </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 px-4" *ngIf="itemsModel.orderTotal > 0">
  </div>
  <div class="container-fluid pt-4 px-4" *ngIf="itemsModel.orderTotal > 0">
    <div class="row g-4">
      <div class="col-sm-12">
        <div class="bg-light rounded h-100 p-4 table-responsive">
          <div class="col-sm-4" *ngIf="!view.isLoadingInyection">
            <div class="form-floating mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Buscar"
                (keyup)="updateFilter($event)"
              />
              <label for="Buscar">Buscar</label>
            </div>
          </div>
          <div class="row g-5">
              <div class="col-md-12 col-lg-12" *ngIf="!view.isLoadingInyection">
                  <ngx-datatable 
                    [rows]="itemsData"
                    [footerHeight]="50"
                    rowHeight="auto"
                    [headerHeight]="50"
                    [limit]="200"
                    class="bootstrap material"
                    [columnMode]="'force'"
                    [summaryRow]="true"
                    [summaryPosition]="'auto'"
                    [limit]="200"
                    [reorderable]="reorderable"
                    [scrollbarH]="true"
                  >
                      <ngx-datatable-column name="create_date">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.create_date}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="modified_date">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.modified_date}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="ordr_version_id">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.ordr_version_id}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="from_event">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.from_event}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="requested_event">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.requested_event}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="status">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.status}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="order_total">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.order_total}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="jsonData">
                          <ng-template let-row="row" ngx-datatable-cell-template>
                              <div class="col-md-11">
                                  <div class="row">
                                      <div class="col-md-7">
                                         {{row.jsonData}}
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                      </ngx-datatable-column>
                  </ngx-datatable>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>